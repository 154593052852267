import React from 'react'
import Button from '../../inputcontrols/Button';
import { keyExist } from '../../../utilities/utilityFns';
import { initVoterCounts, getVotesForParty, getNumOfStates, initVoteValue } from '../../../utilities/utilityFns';
import { SliderComponent } from './SliderComponent';

// ================ isVoteComplete ========================
const isVoteComplete = (values) => {
  return (values.APC.percent + values.PDP.percent + values.LP.percent) === 100;
}

// ================ CommitButton ========================
function CommitButton(props) {

  if(props.votesComplete)
  {
    return( 
      <Button text="Done" variant="contained" onClick={props.onClick} />)
  }
  else
  {
    return(
      <Button text="Not Done" variant="contained" disabled />)
  }
}

// ================ updateVotesByState ========================
const updateVotesByState = (votesValues, stateName, votesPerState, stateLoc) =>
{
  votesValues.stateLoc = {...stateLoc};
  let updatedVotesPerState = [...votesPerState];

  if( keyExist(updatedVotesPerState, stateName) )
  {
    updatedVotesPerState[stateName] = votesValues;

  }
  else
  {
    updatedVotesPerState.push( { [stateName]:votesValues } );
  }

  return updatedVotesPerState;
}

// ================ updateTotalVotes ========================
const updateTotalVotes = ( votesPerState ) =>
{
  let updatedTotalVotes = {...initVoterCounts};
  updatedTotalVotes.APC = getVotesForParty(votesPerState,'APC');
  updatedTotalVotes.PDP = getVotesForParty(votesPerState, 'PDP');
  updatedTotalVotes.LP = getVotesForParty(votesPerState,'LP');
  updatedTotalVotes.howManyStatesSoFar = getNumOfStates(votesPerState);

  return updatedTotalVotes;
}

// ================ SliderVote ========================
export const SliderVote = (props) => {

  const [voteValues, setVoteValues] = React.useState(initVoteValue);

  const handleVoteSubmit = () => {

    let voteValuesTemp = Object.assign({}, voteValues); 
    voteValuesTemp["APC"]['totalVotes'] = Math.round(parseFloat(props.statesData['Voter Registration'].replaceAll(",", "")) * voteValuesTemp["APC"]['percent']/100);//parseFloat(props.statesData) * newValue/100};
    voteValuesTemp["PDP"]['totalVotes'] = Math.round(parseFloat(props.statesData['Voter Registration'].replaceAll(",", "")) * voteValuesTemp["PDP"]['percent']/100);//parseFloat(props.statesData) * newValue/100};
    voteValuesTemp["LP"]['totalVotes'] = Math.round(parseFloat(props.statesData['Voter Registration'].replaceAll(",", "")) * voteValuesTemp["LP"]['percent']/100);//parseFloat(props.statesData) * newValue/100};
    setVoteValues(voteValuesTemp);

    let votesPerState = updateVotesByState(JSON.parse(JSON.stringify(voteValuesTemp)), props.stateName, props.votesPerState, props.stateLoc);
    props.setVotesPerState(votesPerState);
    props.setVoterCounts( updateTotalVotes(votesPerState) )
    //console.log(voteValues, votesPerState)
  }

  return (
    <div className='w-full'>
      <SliderComponent 
        partyName="APC" 
        voteValues={voteValues}
        setVoteValues={setVoteValues}
        statesData={props.statesData['Voter Registration']}  
        disableSlider={props.disableSlider}  
      />
      <SliderComponent 
        partyName="PDP" 
        voteValues={voteValues}
        setVoteValues={setVoteValues} 
        statesData={props.statesData['Voter Registration']}  
        disableSlider={props.disableSlider}    
      />      
      <SliderComponent 
        partyName="LP" 
        voteValues={voteValues}
        setVoteValues={setVoteValues}  
        statesData={props.statesData['Voter Registration']}  
        disableSlider={props.disableSlider}  
      />
      <div>
        <CommitButton votesComplete={isVoteComplete(voteValues)} onClick={handleVoteSubmit}/>
      </div>
    </div>
 )
}