import React, { useEffect } from "react";
import MapPlot from "../../d3plots/MapPlot";
import Button from "../inputcontrols/Button";
import {svgDownload} from '../../utilities/utilityFns'
import { makeMap } from "../../d3plots/MapUtils";

export const NaijaMap = (props) => {

  const handleClick= () => {
    svgDownload(props.svgRef.current);
  }
  useEffect( () => { 
    let mapHandle = makeMap(props.svgRef);
    props.setMapHandle(mapHandle);
  }
  , []); // redraw chart if data changes

  useEffect( () => { 
    MapPlot( props.setMapFeatures, props.handleStates, props.mapHandle, props.stateName, props.mapFeatures, props.votesPerState);
  }
  , [ props.svgRef.current, props.stateName]); // redraw chart if data changes

  return (
    <div className="w-full h-full flex flex-col justify-center items-center"> 
      <svg className="h-5/6 w-full bg-gray-100" ref={props.svgRef}></svg>
      <div className="h-1/6 flex flex-row items-center justify-center">
        {/* <Button text="Download Map Vote" variant="contained" onClick={handleClick} /> */}
      </div>
    </div>
  )
}