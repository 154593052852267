import * as d3 from "d3";
import {  geoGenerator } from "./MapUtils";

// ================= Update =========================
function attachDataToPlot(geojson, handleStates, mapHandle, stateFeatures, statesDone) {
    const mapHandleNew = mapHandle.selectAll("path").data(geojson)
		.join('path')
		.attr('d', geoGenerator)
		.on('mouseover', handleStates.handleSetLoc)
        .on('click', handleStates.handleStateName)
        .attr("class", "state")

    let mapHandleState = mapHandleNew.select("path").data(stateFeatures);
    mapHandleState.enter()
        .append('path')
        .attr('d', geoGenerator)
        .attr("class", "stateselected")

    let mapHandleStateDone = mapHandleNew.select("path").data(statesDone);
    mapHandleStateDone.enter()
        .append('path')
        .attr('d', geoGenerator)
        .attr("class", "statesdone")    
}

// ================= MapPlot =========================
const  MapPlot  = (setMapFeatures, handleStates, mapHandle, stateName, mapFeatures, votesPerState) => {

if(!mapHandle)
{
    return
}

const file_path = "./data/nigeria_state_boundaries.geojson";

// REQUEST DATA
d3.json(file_path)
	.then(function(json) {
        let stateFeatures = mapFeatures.find( (d) => stateName === d.properties.admin1Name )
        if(!stateFeatures)
        {
            stateFeatures = [];
        }

        let statesDone = votesPerState.map( (state) => 
        {
            let stateNameIn = Object.keys(state)[0];
            let s = mapFeatures.find( (d) => stateNameIn === d.properties.admin1Name )
            //console.log(s)
            return s;
        } )
    
    
		attachDataToPlot(json.features, handleStates, mapHandle, [stateFeatures], statesDone);
        setMapFeatures(json.features);
	});
};

export default MapPlot;