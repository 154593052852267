import './App.css';
import { Header } from './components/generic/Header';
import { HomePage } from './pages/HomePage';
import { Footer } from './components/generic/Footer';

function App() {
  return (
    <div className='w-screen flex flex-col justify-center items-center'>
      <div className="App px-2 w-full md:w-4/6 flex flex-col justify-center">
        <Header/>
        <HomePage/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
