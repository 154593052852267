import React, {useEffect, useState} from 'react'
import * as d3 from "d3";
import { SliderVote } from './votecontrols/SliderVote';
import { getStateData } from '../../utilities/utilityFns';
import { cssValues } from '../../utilities/cssStrings';

async function loadStatesData(){
  const dataset = await d3.csv("data/NigeriaStateData2.csv")
  return dataset
}

export const VoteControls = (props) => {

  const [statesData, setStatesData] = useState([])

  useEffect(() => {
    loadStatesData().then( (d) => {
      setStatesData(d);
    });
  }, [])

  return (
    <div className={cssValues.voteControlsColour + ' w-full  h-full flex flex-col justify-between pb-2'}>
        <div> Vote Controls </div>
        <StateSelectedInfo  statesData={statesData} stateName={props.stateName}/>
        <SliderVote  
          setVotesPerState={props.setVotesPerState} 
          stateName={props.stateName} 
          votesPerState={props.votesPerState}
          setVoterCounts={props.setVoterCounts}
          statesData={ getStateData(statesData, props.stateName) }
          disableSlider={ props.stateName === "no state selected" }
          stateLoc={props.stateLoc}
         />
    </div>
  )
}


const StateSelectedInfo = (props) =>
{
  return(
    <div className='w-full flex flex-row'>
      <StateSelected stateName={props.stateName} />
      <QuickInfo stateName={props.stateName} statesData={props.statesData} />
  </div>
  )
}

const StateSelected = (props) => {

    if(props.stateName === "no state selected" )
    {
      return (
        <div className='w-1/2'> Please select a state </div>
      )
    }
    else
    {
      return(
        <div className='w-1/2'>
          <div>You selected: </div>
          <div className='text-2xl'> {props.stateName} </div>
        </div>
      )
    }
  
}

const QuickInfo = (props) => {

  if(props.stateName === "no state selected" )
  {
    return (
      <div className='w-1/2'> {null} </div>
    )
  }
  else
  {
    return(
      <div className='w-1/2'> 
        <div> Quick  Info </div>
        <div> { " Population " + getStateData(props.statesData, props.stateName)['Population (2019)'] } </div>
        <div> { " PVCs Collected " + getStateData(props.statesData, props.stateName)['Voter Registration'] } </div>
    </div>
    )
  }


}