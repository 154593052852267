import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'



const DivState = styled.div`
  /* Adapt the colors based on primary prop */
  // background: ${props => props.primary ? "palevioletred" : "green"};
  color: ${props => props.primary ? "green" : "palevioletred"};
  left: ${ props => props.left || "40px"};
  top: ${ props => props.top || "40px"};
  width: 40px;
  height: 40px;
  pointer-events: none;
  position:relative // use absolute
`;

export const MapStateLabels = (props) => {

  return (
    <>
        { props.statesData.map( (state, index) => { 
            const stateName = Object.keys(state)[0];
            const x = state[stateName]["stateLoc"]["x"] - 20//.stateloc.x + "px";
            const y = state[stateName]["stateLoc"]["y"] - 10 //.stateloc.x + "px";
            return <div className='absolute' key={index}>
              <DivState left={ x + "px" } top={ y + "px"}> 
                <FontAwesomeIcon icon={faCheck} />
              </DivState>
              </div> }    
         ) 
        }
    </>
  )
}
