import * as React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

const Input = styled(MuiInput)`
  width: 42px;
`;

// ================ SliderComponent ========================
export function SliderComponent(props) {
  const [value, setValue] = React.useState(0);

  const handleSliderChange = (event, newValue) => { 
    setValue(newValue);
    updateVoteValues(newValue)
  }

  const updateVoteValues = (newValue) => {
    let voteValues = Object.assign({}, props.voteValues); 
//    voteValues[props.partyName] = {percent: newValue, totalVotes: parseFloat(props.statesData.replaceAll(",", "")) * newValue/100}//parseFloat(props.statesData) * newValue/100};
    voteValues[props.partyName] = {percent: newValue}//parseFloat(props.statesData) * newValue/100};

    props.setVoteValues(voteValues);
  }

  const handleInputChange = (event) => {
    //setValue(event.target.value === '' ? '' : Number(event.target.value));
    handleSliderChange(event, Number(event.target.value))
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <div className='flex flex-row'>
        <div className='w-1/6'> {props.partyName} </div>
        <div className='w-4/6 flex flex-row items-center justify-center px-2'>
            <Slider
                disabled={props.disableSlider}
                value={typeof value === 'number' ? value : 0}
                onChange={handleSliderChange}
                // aria-labelledby="input-slider"
            />
        </div>
        <div className='w-1/6 flex flex-row items-center jusifty-center px-2'>
            <Input
                value={value}
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                }}
            />
        </div>
    </div>
    );
}