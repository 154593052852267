import React from 'react'
import { cssValues } from '../../utilities/cssStrings'
import EmailForm from '../emailform/EmailForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'


export const Footer = () => {
  return (
    <div  className={cssValues.headerColour + ' h-20 flex flex-row px-2 justify-center'}>
      <div className='mx-2 flex flex-row items-center'><EmailForm /></div>
      <div className='mx-2 flex flex-row items-center'>
        <a href='https://twitter.com/whogowinam'>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      {/* <div className='mx-2 flex flex-row items-center'>
        <a href=''>
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </div>
      <div className='mx-2 flex flex-row items-center'>
        <a href=''>
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      <div className='mx-2 flex flex-row items-center'>
        <a href=''>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div> */}
   </div>
  )
}
