import React, {useEffect, useState} from 'react'

// ================ getReturnValues ========================
const getReturnValues = (countDown) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  
    return [days, hours, minutes, seconds];
  };
    
// ================ useCountDown ========================
const UseCountdown = (props) => {
  const countDownDate = new Date(props.targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  let [days, hours, minutes, seconds] = getReturnValues(countDown)

  return (
    <div className='flex flex-row justify-center w-full'>
        <TimeComponent title={days} str="days" />
        <TimeComponent title={hours} str="hours" />
        <TimeComponent title={minutes} str="mins" />
        <TimeComponent title={seconds} str="secs" />
    </div>
  )
};

const TimeComponent = (props) => {

  return(
    <div className='px-2  flex flex-row'>
        <div className='text-2xl'> {props.title} </div>
        <div className='text-sm'> {props.str}   </div>
    </div> 
  )
}

// ================ CountDown ========================
export const CountDown = () => {
    const targetDate =  "2023-02-25"
    return (
        <div className='w-4/6 flex flex-col items-center justify-center'>
            <div className='text-red-700 text-sm'> Coming Soon - Live Election Tracker  </div>
            <UseCountdown targetDate={targetDate} />
            <div className='text-sm'> <h6>countdown to the presidency elections (Feb  25  2023)</h6></div>
        </div>
    )
}