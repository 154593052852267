import * as d3 from "d3";

// ================= Globals =========================
// Dimensions

let width = window.innerWidth/3;
export let mapDimensions = {
  width: width, //1000,
  height: width,
  x: 0,
  y: 0,
  scale: width < 300 ? 1500: 3000,
};

// console.log(window.innerWidth)
  
// const width = window.innerWidth/3
// let dimensions = {
//   width: width,
//   height: width < 300 ? width*0.8: width * 0.3,
//   margin: {


// mapDimensions.containerWidth = mapDimensions.width - mapDimensions.margins * 2;
// mapDimensions.containerHeight = mapDimensions.height - mapDimensions.margins * 2;

// ================= mapProjection =========================
export let mapProjection = d3.geoMercator()
    .scale(mapDimensions.scale)
    // .fitWidth(200)
    // .fitHeight(200)
    .translate([0, 0])
    .center([2.0, 14.16]);
  
// ================= geoGenerator =========================
export let geoGenerator = d3.geoPath()
    .projection(mapProjection);

// ================= handleMouseover =========================
export function handleMouseOver(e, d) {

    let bounds    = geoGenerator.bounds(d);

  
    // d3.select('#content .info')
    //     .text(d.properties.name + ' (path.area = ' + pixelArea.toFixed(1) + ' path.measure = ' + measure.toFixed(1) + ')');
  
    // d3.select('#content .bounding-box rect')
    d3.select('rect')
        .attr('x', bounds[0][0])
        .attr('y', bounds[0][1])
        .attr('width', bounds[1][0] - bounds[0][0])
        .attr('height', bounds[1][1] - bounds[0][1])


    //setStateProperties()

    //console.log(centroid, bounds)
   // console.log(d)
  
    // d3.select('#content .centroid')
    //     .style('display', 'inline')
    //     .attr('transform', 'translate(' + centroid + ')');
  
    //return d.properties.admin1Name
  }

// ================= redrawData =========================
export function redrawData(mapFeatures, handleFill, handleStateName, svgRef){
    let u = d3.select(svgRef.current)
      .attr("width", mapDimensions.width)
      .attr("height", mapDimensions.height)
      .append("g")
      .classed("container", true)
      .attr("transform", `translate(${mapDimensions.x}, ${mapDimensions.y})`)
      .selectAll('path')
      .data(mapFeatures)
  
      u.enter()
      .append('path')
      .attr('d', geoGenerator)
      .on('mouseover', handleMouseOver)
      .on('click', handleStateName)
      .attr("class", handleFill)
  }


export const makeMap = (svgRef) => {
  let mapHandle = d3.select(svgRef.current)
  .attr("width", mapDimensions.width)
  .attr("height", mapDimensions.height)
  .append("g")
  .attr("transform", `translate(${mapDimensions.x}, ${mapDimensions.y})`)

   return mapHandle;
}