import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { send } from 'emailjs-com';

import Alert from '@mui/material/Alert';


const initMessage = {
  firstname: "nobody",
  lastname: "nobody",
  email: "rubbish",
  phonenumber: 0,
  comments: "nothing"
}


const sendMessage  = (messageObj)  => {


  const message = {
    from_name: messageObj.firstname + " " + messageObj.lastname + " " +  messageObj.phonenumber,
    to_name: '',
    message: messageObj.comments,
    reply_to: messageObj.email,
  }

  send(
    "service_cow82pk",
    'template_62unnmi',
    message,
    "5Xtw_ftjJphca4IDH"
  )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      console.log('FAILED...', err);
    });
}

export default function EmailForm() {

  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false)
  const [message, setMessage] = React.useState(initMessage);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setOpen(false);
    setOpenAlert(true)
    // this is where we send the message
    sendMessage(message)
  }

  const handleContactForm = (e) => {
    setMessage(
      {
        ...message, [e.target.id] : e.target.value
      }
    )
  }
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Contact Us
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To contact us, please send your questions/comments using the form below.
          </DialogContentText>
          <div className='flex flex-row w-full'>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="firstname"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="lastname"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
          </div>
          <div className='flex flex-row w-full'>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="phonenumber"
                label="Phone"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="comments"
            label="Questions/Comments"
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            onChange={handleContactForm}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend}>Send</Button>
        </DialogActions>
      </Dialog>
      <div className='absolute top-0'>
        {openAlert ? <Alert onClose={ () => { setOpenAlert(false)} }>Message Sent!</Alert> : null}
      </div>
    </div>
  );
}