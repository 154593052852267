import React, {useEffect} from 'react'
import { BarPlot } from '../../d3plots/BarPlot';
import { VoteControls } from './VoteControls';
import styled from 'styled-components'


import apcLogo from '../../images/apcLogo.jpeg'
import pdpLogo from '../../images/pdpLogo.jpeg'
import lpLogo from '../../images/lpLogo.png'
import { CountDown } from '../generic/CountDown';


const DivStyled = styled.div`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "red"};
  color: ${props => props.primary ? "green" : "palevioletred"};
  height:  ${ props => props.height + "%" || "30%"};
  width:  ${ props => props.width || "50%"};
  position: absolute;
  bottom: 0;
  `;

export const VotesPerPartyHistogram2 = (props) => {

  return (
    <div className='w-full md:h-full flex flex-col md:flex-row'>
        <div className=' block md:hidden flex flex-row justify-center w-full'>
          <CountDown/>
        </div>
        <div className=' md:h-full w-full md:w-1/2 border-2'> 
          <VoteControls stateName={props.stateName} 
                    setVotesPerState={props.setVotesPerState} 
                    votesPerState={props.votesPerState} 
                    setVoterCounts={props.setVoterCounts}
                    stateLoc={props.rectLoc}
          />
        </div>
        <div className={"h-80 md:h-full w-full md:w-1/2 border-2 relative"}>
          <div className='w-full'> 
            { "You have completed " + props.voterCounts.howManyStatesSoFar + " of 37 States" }
          </div>
          <div className='h-5/6 w-full absolute bottom-0'>
            <VotesHist dataset={props.dataset}/>
          </div>
        </div>
    </div>
  )
}

const VotesHist = (props) =>{

  return(
    <div className='w-full h-full flex flex-row space-between justify-center'>
      <VoteDetails partyname={props.dataset[0].partyDetails.name} partyvotes={<DivStyled height={props.dataset[0].partyDetails.percent}/>} partyflag={apcLogo} percentvote={props.dataset[0].partyDetails.percent} totaltvote={props.dataset[0].partyDetails.total} />
      <VoteDetails partyname={props.dataset[1].partyDetails.name} partyvotes={<DivStyled height={props.dataset[1].partyDetails.percent}/>} partyflag={pdpLogo} percentvote={props.dataset[1].partyDetails.percent} totaltvote={props.dataset[1].partyDetails.total}/>
      <VoteDetails partyname={props.dataset[2].partyDetails.name} partyvotes={<DivStyled height={props.dataset[2].partyDetails.percent}/>} partyflag={lpLogo} percentvote={props.dataset[2].partyDetails.percent} totaltvote={props.dataset[2].partyDetails.total}/>
      {/* <VoteDetails partyname={"NNPC"} partyvotes={0} partyflag={"NNPC FLAGS"}/> */}
    </div>
  )
}

const VoteDetails = (props) => {
  return(
    <div className='w-1/4 flex flex-col'>
      <div className='h-1/6 text-sm mb-5'> {props.partyname + " = " + props.percentvote + "%" + "(" + props.totaltvote.toLocaleString("en-US") + ") votes" } </div>
      <div className='h-4/6 relative px-5 flex flex-row justify-center '> {props.partyvotes} </div>
      <div className='h-1/6'> 
        <img className='w-full h-full px-5 py-2' src={props.partyflag} alt="Logo" onClick={""}/>
      </div>
    </div>
  )
}



