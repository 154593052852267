import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { send } from 'emailjs-com';
import { Alert } from '@mui/material';


const initMessage = {
  firstname: "nobody",
  lastname: "nobody",
  email: "rubbish",
  phonenumber: 0,
  comments: "nothing"
}


const sendMessage  = (messageObj)  => {

  const message = {
    from_name: messageObj.firstname + " " + messageObj.lastname + " " +  messageObj.phonenumber,
    to_name: '',
    message: "this person subscribed",
    reply_to: messageObj.email,
  }

  send(
    "service_cow82pk",
    'template_62unnmi',
    message,
    "5Xtw_ftjJphca4IDH"
  )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
      console.log('FAILED...', err);
    });
}

export default function SubscribeForm() {
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState(initMessage);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setOpen(false);
    setOpenAlert(true)
    // this is where we send the message
    console.log( message )
    sendMessage(message)
  }

  const handleContactForm = (e) => {
    setMessage(
      {
        ...message, [e.target.id] : e.target.value
      }
    )
  }
  return (
    <div className='w-full'>
      <a className='cursor-pointer rounded-lg p-4 bg-gray-100 hover:bg-gray-300' onClick={handleClickOpen}>
        Subscribe
      </a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe to whogowinam?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Subscribe to get up to date election news
          </DialogContentText>
          <div className='flex flex-row w-full'>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="firstname"
                label="First Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="lastname"
                label="Last Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
          </div>
          <div className='flex flex-row w-full'>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
            <div className='w-1/2'>
              <TextField
                autoFocus
                margin="dense"
                id="phonenumber"
                label="Phone"
                type="text"
                fullWidth
                variant="standard"
                onChange={handleContactForm}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend}>Subscribe</Button>
        </DialogActions>
      </Dialog>
      <div className='absolute'>
        {openAlert ? <Alert onClose={ () => { setOpenAlert(false)} }>You are subscribed</Alert> : null}
      </div>
    </div>
  );
}