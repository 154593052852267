import React from 'react'
import { cssValues } from '../../utilities/cssStrings'
import { CountDown } from './CountDown'
import logo from '../../images/whogowinamlogo.png'
import SubscribeForm from '../subscribeform/SubscribeForm'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);


const LogoSection = (props) =>{

  return(
    <div className='pt-2 w-48'> 
      <img className='w-48 h-full cursor-pointer' src={logo} alt="Logo"  onClick={""}/> 
    </div>
  )

}

const LoginSection = (props) =>{

  return(
    <div className='pt-8'> 
      <SubscribeForm/>
    </div>
  )

}

export const Header = () => {
  return (
    <div className={cssValues.headerColour + ' w-full h-20 flex flex-row sm:flex-row md:flex-row justify-between'}>
      <LogoSection />
      <div className='hidden md:block'><CountDown/></div>
      <LoginSection />
    </div>
  )
}
