import React, { useState, useRef }  from 'react'
import { NaijaMap } from '../components/home/NaijaMap'
import { handleMouseOver }  from '../d3plots/MapUtils'
import {initVoterCounts} from '../utilities/utilityFns'
import { geoGenerator } from '../d3plots/MapUtils'
import styled from 'styled-components'
import { MapStateLabels } from '../components/home/MapStateLabels'
import { VotesPerPartyHistogram2 } from '../components/home/VotesPerPartyHistogram2'
import { convertVotesToBar } from '../utilities/utilityFns'

const initLoc = {
  x: 0,
  y: 0
}

const DivStyled = styled.div`
  /* Adapt the colors based on primary prop */
  // background: ${props => props.primary ? "palevioletred" : "green"};
  color: ${props => props.primary ? "green" : "palevioletred"};
  left: ${ props => props.left || "40px"};
  top: ${ props => props.top || "40px"};
  position: absolute;
  width: 40px;
  height: 40px;
  pointer-events: none;
`;

export const HomePage = () => {

  const svgRef = useRef(null);
  const svgRefBar = useRef(null)

  const [stateName, setStateName] = useState("no state selected");
  const [stateNameMouse, setStateNameMouse] = useState("select a state");
  const [votesPerState, setVotesPerState] = useState([])
  const [voterCounts, setVoterCounts] = useState(initVoterCounts);
  const [rectLoc, setRectLoc] =  useState(initLoc);
  const [rectLocMouse, setRectLocMouse] =  useState(initLoc);

  const [mapHandle, setMapHandle] = useState(null)
  const [mapFeatures, setMapFeatures] = useState([]);

  // ================= handleFill =========================
  const handleFill = (d) => {
    if(d.properties.admin1Name === stateName)
    {
      console.log(d.properties.admin1Name, stateName)
      return "stateselected"
    }
    else 
    {
      console.log(stateName )
      return "state"
    }
  }

  // ================= handleStateName =========================
  const handleStateName = (e, d)=> {
    setStateName(d.properties.admin1Name)

    // let pixelArea = geoGenerator.area(d);
    // let bounds    = geoGenerator.bounds(d);
    let centroid  = geoGenerator.centroid(d);
    // let measure   = geoGenerator.measure(d);

    setRectLoc(
      {
        x: centroid[0],
        y: centroid[1]
      }
    )
  }

  // ================= handleSetLoc =========================
  const handleSetLoc = (e, d)=> {
    let centroid  = geoGenerator.centroid(d);

    setRectLocMouse(
      {
        x: centroid[0],
        y: centroid[1]
      }
    )
    setStateNameMouse(d.properties.admin1Name)
  }

  // ================= handleSetMapFeatures =========================
  const handleSetMapFeatures = (e, d) => 
  {
    setMapFeatures();
  }

  const handleStates = {
    handleStateName: handleStateName,
    handleSetLoc: handleSetLoc,
    handleSetMapFeatures: handleSetMapFeatures
  }

  return (
    <div className='flex flex-row w-full md:h-screen'>
        <div className='w-full h-full flex flex-col'>
            <div className=' md:h-2/6 w-full flex flex-col'>
              <div className='text-2xl pt-8 pb-2 flex flex-row justify-start'> 2023 Nigerian Elections Playground </div>
              <VotesPerPartyHistogram2  dataset={convertVotesToBar(voterCounts)}
                stateName={stateName} 
                setVotesPerState={setVotesPerState} 
                votesPerState={votesPerState} 
                setVoterCounts={setVoterCounts}
                rectLoc={rectLoc}
                voterCounts={voterCounts}
              />
            </div>
            <div className='h-4/6 relative'>
              <DivStyled left={rectLocMouse.x - 10  + "px"} top={rectLocMouse.y - 10 + "px"}> {stateNameMouse}</DivStyled>
              <MapStateLabels statesData={votesPerState}/>
              <NaijaMap handleStateName={handleStateName} 
                      handleStates={handleStates}
                      handleFill={handleFill} 
                      handleMouseOver={handleMouseOver}
                      svgRef={svgRef}
                      stateName={stateName}
                      mapHandle={mapHandle}
                      setMapHandle={setMapHandle}
                      mapFeatures={mapFeatures}
                      setMapFeatures={setMapFeatures}
                      votesPerState={votesPerState}/>
            </div>
        </div>
    </div>
  )
}