import * as d3 from "d3";
 
export const initVoterCounts = {

    howManyStatesSoFar: 0,
    PDP:                0,
    APC:                0,
    LP:                 0,
}

// ================ intVote ========================
export const initVoteValue = {
    "APC": {percent: 0, totalVotes: 0},
    "PDP": {percent: 0, totalVotes: 0},
    "LP":  {percent: 0, totalVotes: 0},
  };
  
// ================ keyExist ========================
export const keyExist = ( arr, str) => 
{
    return  arr.some(obj => str in obj)
}

// ================ howManyInArray ========================
export const howManyInArray = (arr) =>
{
    return 0;
}

// ================ getVotesForParty ========================
export const getVotesForParty = (votesPerState, partyStr) =>
{
    let sum = votesPerState.reduce( function(accumulator, currentValue){
        let state = Object.keys(currentValue)[0]
        return accumulator + currentValue[state][partyStr]['totalVotes'];
    }, 0);
    return sum;
}

// ================ getNumOfStates ========================
export const getNumOfStates = (votesPerState) =>
{
    return votesPerState.length;
}

// ================ svgDownload ========================
export function svgDownload(svg)
{
    console.log(svg.style.color)
    svg.style.fill = "white";
    svg.style.stroke="cornflowerblue"
    svg.style.strokeWidth=2;
    // function to download an image from the DOM.
    function triggerDownload (imgURI) {
        let evt = new MouseEvent('click', {
            view: window,
            bubbles: false,
            cancelable: true
        });

        let a = document.createElement('a');
        a.setAttribute('download', 'MY_COOL_IMAGE.png');
        a.setAttribute('href', imgURI);
        a.setAttribute('target', '_blank');

        a.dispatchEvent(evt);
    }

    let data = (new XMLSerializer()).serializeToString(svg);
    let DOMURL = window.URL || window.webkitURL || window;

    let svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
    let url = DOMURL.createObjectURL(svgBlob);


    let img = new Image();
    img.onload = function () {
        let canvas = d3.select('body').append('canvas').node();
        canvas.width = 1000;
        canvas.height = 1000;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        DOMURL.revokeObjectURL(url);

        let imgURI = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');

        triggerDownload(imgURI);
        d3.select("canvas").remove();
    };

    img.src = url;
}

// ================ convertVotesToBar ========================

export const convertVotesToBar = (voterCounts) => {

const votesPercent = convertVotesToPercent(voterCounts);

const dataset = [
    { partyDetails:  {
                            name: "APC",
                            percent: votesPercent.APC,
                            total: voterCounts.APC,
                            index: 0,
            }
    },

    { partyDetails:  {
                            name: "PDP",
                            percent: votesPercent.PDP,
                            total: voterCounts.PDP,
                            index: 1,
            }
    },      

    { partyDetails:     {
                            name : "LP",
                            percent: votesPercent.LP,
                            total: voterCounts.LP,
                            index: 2,
                        }
    },
];

//console.log(dataset)

return dataset;
}

// ================ convertVotesToPercent ========================
export  const convertVotesToPercent = (voterCounts) =>{
    let totalVotes = voterCounts.PDP + voterCounts.APC + voterCounts.LP;
    let percentageAPC = Math.round(voterCounts.APC/totalVotes* 100) || 0;
    let percentagePDP = Math.round(voterCounts.PDP/totalVotes* 100) || 0;
    let percentageLP = Math.round(voterCounts.LP/totalVotes* 100) || 0;

    return {
        APC: percentageAPC,
        PDP: percentagePDP,
        LP: percentageLP
    }
}


export const getStateData = (statesData, stateName) =>
{
  if(statesData)
  {
    const selectedStateData = statesData.find( d => d.State === stateName  )
    if(selectedStateData){
      //return selectedStateData["Population (2019)"];
      //console.log(selectedStateData)
      return selectedStateData;//["Voter Registration"];
    }
    else
      return "0";
  }
  else
  {
    return "None";
  }
}